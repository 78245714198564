import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Warn_Banner from "./global/Warn_Banner";
import { gql, useMutation } from "@apollo/client";
import { Custom_Build } from "../backend-generated-types";
import Icon_Desktop from "./icons/Icon_Desktop";
import PaidIcon from "@mui/icons-material/Paid";
import TimelapseIcon from "@mui/icons-material/Timelapse";

type Props = {
  build: Custom_Build;
  handle_delete: (id: string) => void;
};

const appraisal_text_style = {
  textAlign: "center" as "center",
};

const garage_item_style = {
  margin: "20px",
  maxWidth: "250px",
  maxHeight: "300px",
  display: "flex",
  flexDirection: "column" as "column",
  position: "relative" as "relative",
};

const triple_dot_btn_style = {
  position: "absolute" as "absolute",
  top: 0,
  right: 15,
  zIndex: 200,
};

const desktop_graphic_style = {
  marginTop: 10,
  width: "80%",
  height: "150px",
  marginLeft: "auto",
  marginRight: "auto",
};

const row_container_style = {
  display: "flex",
  flexDirection: "row" as "row",
  justifyContent: "right",
};

const modal_style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  color: "text.primary",
  border: "1px solid green",
  p: 4,
  fontFamily: "Ubuntu",
};

const Garage_Item = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [delete_modal_open, set_delete_modal_open] = React.useState(false);
  const handle_delete_modal_open = () => set_delete_modal_open(true);
  const handle_delete_modal_close = () => set_delete_modal_open(false);
  const triple_dot_open = Boolean(anchorEl);
  const [mouseover, set_mouseover] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const execute_deletion = () => {
    delete_custom_build_mutation({
      variables: {
        id: props.build?.id,
      },
    }).then((_) => {
      handle_delete_modal_close();
      props.handle_delete(props.build?.id);
    });
  };
  const [delete_custom_build_mutation] = useMutation(gql`
    mutation Delete_Custom_Build($id: ID!) {
      custom_build_delete(id: $id) {
        id
      }
    }
  `);
  return (
    <>
      <div
        style={garage_item_style}
        onMouseOver={() => set_mouseover(true)}
        onMouseLeave={() => set_mouseover(false)}
      >
        {mouseover && (
          <>
            <Button
              id="basic-button"
              variant={"outlined"}
              aria-controls={
                triple_dot_open ? "garage-item-overlay" : undefined
              }
              aria-haspopup="true"
              aria-expanded={triple_dot_open ? "true" : undefined}
              onClick={handleClick}
              style={triple_dot_btn_style}
              sx={{
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <MoreHorizIcon />
            </Button>
            <Menu
              id="garage-item-overlay"
              anchorEl={anchorEl}
              open={triple_dot_open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handle_delete_modal_open();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}

        <div style={desktop_graphic_style}>
          <Icon_Desktop
            is_static={true}
            cpu_enabled={!!props.build?.cpus?.length}
            gpu_enabled={!!props.build?.gpus?.length}
            psu_enabled={!!props.build?.psus?.length}
            cpu_cooler_enabled={!!props.build?.cpu_coolers?.length}
            case_enabled={!!props.build?.case?.id}
            case_fan_enabled={!!props.build?.case_fans?.length}
            motherboard_enabled={!!props.build?.motherboard?.id}
            operating_system_enabled={!!props.build?.operating_systems?.length}
            ram_enabled={!!props.build?.rams?.length}
            storage_enabled={!!props.build?.storages?.length}
            max_width={200}
          />
        </div>
        <Link href={`/builds/${props.build?.id}`} underline={"none"}>
          <Typography style={{ ...appraisal_text_style, color: "black" }}>
            {props.build?.name}
          </Typography>
          {props.build?.latest_appraisal_price && (
            <Typography
              fontStyle={"italic"}
              variant={"body2"}
              style={appraisal_text_style}
            >
              ${props.build?.latest_appraisal_price}
            </Typography>
          )}
        </Link>
      </div>
      <Modal
        open={delete_modal_open}
        onClose={handle_delete_modal_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <Warn_Banner
            message={
              "You would permanently lose any associated build information or price history."
            }
          />
          <div style={row_container_style}>
            <Button
              type={"submit"}
              variant={"contained"}
              onClick={handle_delete_modal_close}
              sx={{ my: 2, display: "block", marginRight: "10px" }}
            >
              {"Cancel"}
            </Button>
            <Button
              type={"submit"}
              variant={"outlined"}
              onClick={execute_deletion}
              sx={{ my: 2, display: "block" }}
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Garage_Item;
