import React from "react";
import Icon_Case from "./Icon_Case";
import Icon_Case_Fan from "./Icon_Case_Fan";
import Icon_Cpu from "./Icon_Cpu";
import Icon_Cpu_Fan from "./Icon_Cpu_Fan";
import Icon_Gpu from "./Icon_Gpu";
import Icon_Motherboard from "./Icon_Motherboard";
import Icon_Operating_System from "./Icon_Operating_System";
import Icon_Psu from "./Icon_Psu";
import Icon_Ram from "./Icon_Ram";
import Icon_Storage from "./Icon_Storage";



type Props = {
    is_static: boolean;

    case_enabled?: boolean;
    cpu_cooler_enabled?: boolean;
    motherboard_enabled?: boolean;
    cpu_enabled?: boolean;
    gpu_enabled?: boolean;
    psu_enabled?: boolean;
    ram_enabled?: boolean;
    operating_system_enabled?: boolean;
    storage_enabled?: boolean;
    case_fan_enabled?: boolean;

    max_width?: number;
}

const desktop_container_style = {
    position: "relative" as "relative",
}

const desktop_icon_style = {
    zIndex: 1,
    width: '100%',
    height: '10%',
    position: "absolute" as "absolute",
    top: '50%',
    transform: 'translateY(-50%)',
}

const Icon_Desktop = (props: Props) => {
    const enabled_comps = [props.case_enabled, props.cpu_cooler_enabled, props.motherboard_enabled, props.cpu_enabled, props.gpu_enabled, props.psu_enabled, props.case_fan_enabled, props.ram_enabled, props.operating_system_enabled, props.storage_enabled];
    const comp_amount = enabled_comps.filter(c => !!c).length;
    const use_default_icon = comp_amount > 1;
    return (
        <div style={desktop_container_style}>
            <div style={{...desktop_icon_style, maxWidth: props.max_width}}>
                {(use_default_icon || props.case_enabled) && <Icon_Case />}
                {!use_default_icon && props.motherboard_enabled && <Icon_Motherboard />}
                {!use_default_icon && props.cpu_enabled && <Icon_Cpu />}
                {!use_default_icon && props.gpu_enabled && <Icon_Gpu />}
                {!use_default_icon && props.cpu_cooler_enabled && <Icon_Cpu_Fan />}
                {!use_default_icon && props.operating_system_enabled && <Icon_Operating_System />}
                {!use_default_icon && props.ram_enabled && <Icon_Ram />}
                {!use_default_icon && props.storage_enabled && <Icon_Storage />}
                {!use_default_icon && props.psu_enabled && <Icon_Psu />}
                {!use_default_icon && props.case_fan_enabled && <Icon_Case_Fan />}
            </div>
        </div>
    )
}

export default Icon_Desktop;