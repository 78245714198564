import { Alert, AlertTitle } from "@mui/material";
import * as React from 'react';

type Props = {
    message: string;
}
                    

const Warn_Banner: React.FC<Props> = ({
    message
  }) => {
    return (
        <Alert sx={{margin: 2}} severity="warning">
            <>
                <AlertTitle>Delete Custom Build?</AlertTitle>
                {
                    message
                }
            </>
        </Alert>
    );
  }

export default Warn_Banner;