import * as React from "react";
import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Props {}

const ScrollTop = (props: Props) => {

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });
  
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).getElementsByTagName('header').item(0);
  
      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
  
    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Fab sx={{bgcolor: "#39B54A"}} size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon sx={{color: "#fff"}} />
                </Fab>
            </Box>
        </Fade>
    );
  }

export default ScrollTop;