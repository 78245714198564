import React from "react";
import { case_back, case_lining } from "../../images";

const back_case_style = {
  zIndex: 0,
  position: "absolute" as "absolute",
};

const front_case_style = {
  zIndex: 100,
  position: "absolute" as "absolute",
};

const Icon_Case = () => {
  return (
    <div>
      <img style={back_case_style} src={case_back} />
      <img style={front_case_style} src={case_lining} />
    </div>
  );
};

export default Icon_Case;
