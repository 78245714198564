import React from "react";
import { storage } from "../../images";

const default_storage_container_style = {
  position: "absolute" as "absolute",
  width: "100%",
};

const Icon_Storage = () => {
  return (
    <>
      <div style={default_storage_container_style}>
        <img src={storage} />
      </div>
    </>
  );
};

export default Icon_Storage;
