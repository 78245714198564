import React, { useEffect, useRef, useState } from "react";
import { cpu } from "../../images";

const default_cpu_container_style = {
  position: "absolute" as "absolute",
  width: "100%",
};

const Icon_Cpu = () => {
  return (
    <>
      <div style={default_cpu_container_style}>
        <img src={cpu} />
      </div>
    </>
  );
};

export default Icon_Cpu;
