import React from "react";
import { gpu } from "../../images";

const default_gpu_container_style = {
  position: "absolute" as "absolute",
  width: "100%",
};

const Icon_Gpu = () => {
  return (
    <>
      <div style={default_gpu_container_style}>
        <img src={gpu} />
      </div>
    </>
  );
};

export default Icon_Gpu;
