import React from "react";
import { ram } from "../../images";

const default_ram_container_style = {
  position: "absolute" as "absolute",
  display: "flex",
  flexDirection: "row" as "row",
  justifyContent: "space-between" as "space-between",
  width: "100%",
};

const Icon_Ram = () => {
  return (
    <>
      <div style={default_ram_container_style}>
        <img width={2} src={ram} />
        <img width={2} src={ram} />
        <img width={2} src={ram} />
        <img width={2} src={ram} />
      </div>
    </>
  );
};

export default Icon_Ram;
