import React from "react";
import { cpu_fan, cpu_fan_back, cpu_fan_front } from "../../images";

const CPU_COOLER_SCALE = 80;

const base_style = {
  zIndex: 2,
  position: "absolute" as "absolute",
  width: "100%",
};

const back_style = {
  position: "absolute" as "absolute",
  top: 2,
  left: 1,
};

const front_style = {
  position: "absolute" as "absolute",
  zIndex: 4,
};

const default_spinning_part_style = {
  position: "absolute" as "absolute",
  zIndex: 3,
  top: 5,
  left: 1,
};

const Desktop_Cpu_Fan = () => {
  return (
    <>
      <div style={base_style}>
        <img style={back_style} src={cpu_fan_back} />
        <img style={default_spinning_part_style} src={cpu_fan} />
        <img style={front_style} src={cpu_fan_front} />
      </div>
    </>
  );
};

export default Desktop_Cpu_Fan;
